<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <h4 class="mb-3">
        Редактирование доверенности ID{{ attorneyId }}
      </h4>
      <div class="form-sections">
        <div class="custom-fieldset">
          <b-row>
            <b-col md="12">
              <b-form-group
                id="driversInputGroup"
                class="form-group-el-select"
                label="Водители"
                label-for="drivers"
              >
                <el-select-clearable
                  id="drivers"
                  ref="editDriverAttorney"
                  v-model="form.drivers.value"
                  class="form-control"
                  name="drivers"
                  placeholder="Начните вводить Фамиилию/Имя водителя"
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="driverRemote"
                  :loading="loading"
                  @focus="driverRemote('')"
                  @change="onDriverChoose(form.drivers.value)"
                >
                  <el-option
                    v-for="item in form.drivers.options"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                    :disabled="item.disabled"
                  />
                </el-select-clearable>
              </b-form-group>
              <v-client-table
                v-if="drivers.items"
                id="dataTable"
                :data="drivers.items"
                :columns="drivers.columns"
                :options="drivers.options"
                :theme="drivers.theme"
              >
                <span
                  slot="driver.status"
                  slot-scope="props"
                >
                  <span
                    v-if="props.row.driver.status === 0"
                    class="text-secondary"
                  > Заблокирован СБ </span>
                  <span
                    v-if="props.row.driver.status === 1"
                    class="text-success"
                  > Допущен </span>
                  <span
                    v-if="props.row.driver.status === 2"
                    class="text-primary"
                  > На верификации </span>
                  <span
                    v-if="props.row.driver.status === 3"
                    class="text-danger"
                  > Не допущен </span>
                  <span
                    v-if="props.row.driver.status === 4"
                    class="text-primary"
                  > Проверка логистом </span>
                  <span
                    v-if="props.row.driver.status === 5"
                    class="text-danger"
                  > Отклонен </span>&ensp;
                  <span
                    v-if="props.row.driver.blockedSecurity"
                    class="badge badge-danger"
                  >Блокировка СБ</span>
                </span>
                <span
                  slot="driver.passportIssueDate"
                  slot-scope="props"
                >{{ formatDatetime(props.row.driver.passportIssueDate) }}</span>
                <span
                  slot="driver.passportScreenFirst"
                  slot-scope="props"
                >
                  <span v-if="props.row.passportScreenFirst">
                    <p v-if="props.row.passportScreenFirst.file">
                      <a
                        :href="'/files/' + props.row.passportScreenFirst.file.guid"
                        target="_blank"
                        @click.prevent="downloadFile(props.row.passportScreenFirst.file)"
                      >Скачать</a>
                    </p>
                  </span>
                  <span v-else>Н/Д</span>
                </span>
                <span
                  slot="driver.passportScreenSecond"
                  slot-scope="props"
                >
                  <span v-if="props.row.passportScreenSecond">
                    <p v-if="props.row.passportScreenSecond.file">
                      <a
                        :href="'/files/' + props.row.passportScreenSecond.file.guid"
                        target="_blank"
                        @click.prevent="downloadFile(props.row.passportScreenSecond.file)"
                      >Скачать</a>
                    </p>
                  </span>
                  <span v-else>Н/Д</span>
                </span>
                <span
                  slot="contractFile"
                  slot-scope="props"
                >
                  <span v-if="props.row.contractFiles.length > 0">
                    <p
                      v-for="(file, index) in props.row.contractFiles"
                      :key="`file-${index}`"
                    >
                      <a
                        :href="'/files/' + file.file.guid"
                        target="_blank"
                        @click.prevent="downloadFile(file.file)"
                      >Скачать</a>
                    </p>
                  </span>
                  <span v-else>Н/Д</span>
                </span>
                <span
                  slot="actions"
                  slot-scope="props"
                >
                  <a
                    v-if="$store.getters.isAdmin"
                    v-b-tooltip.hover.bottom="'Удалить'"
                    class="fa fa-close fa-lg text-danger table-action-button"
                    @click="deleteChosenDriver(props.row.id)"
                  />
                </span>
              </v-client-table>
              <div class="d-flex justify-content-end">
                <b-button
                  type="button"
                  variant="danger"
                  :class="{'loading' : loading}"
                  :disabled="loading || !drivers.items.length"
                  @click="clearDriversList()"
                >
                  Очистить список перевозчиков
                </b-button>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyNumberInputGroup"
                label="Номер доверенности"
                label-for="attorney-number"
              >
                <b-form-input
                  id="attorney-number"
                  v-model.trim="form.number"
                  type="text"
                  name="attorney-number"
                  aria-describedby="attorneyNumberLiveFeedback"
                  autocomplete="attorney-number"
                  required
                />
                <b-form-invalid-feedback id="attorneyNumberLiveFeedback">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyDateInputGroup"
                label="Дата доверенности"
                label-for="attorney-date"
              >
                <el-date-picker-input
                  id="attorney-date"
                  v-model.trim="form.date"
                  type="date"
                  name="attorney-date"
                  autocomplete="attorney-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  aria-describedby="attorneyDateLiveFeedback"
                  required
                />
                <b-form-invalid-feedback id="attorneyDateLiveFeedback">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyDateStartInputGroup"
                label="Период действия с"
                label-for="attorney-date-start"
              >
                <el-date-picker-input
                  id="attorney-date-start"
                  v-model.trim="form.dateStart"
                  type="date"
                  name="attorney-date-start"
                  autocomplete="attorney-date-start"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  aria-describedby="attorneyDateStartLiveFeedback"
                  required
                />
                <b-form-invalid-feedback id="attorneyDateStartLiveFeedback">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyDateEndInputGroup"
                label="Период действия по"
                label-for="attorney-date-end"
              >
                <el-date-picker-input
                  id="attorney-date-end"
                  v-model.trim="form.dateEnd"
                  type="date"
                  name="attorney-date-end"
                  autocomplete="attorney-date-end"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  aria-describedby="attorneyDateEndLiveFeedback"
                  required
                />
                <b-form-invalid-feedback id="attorneyDateEndLiveFeedback">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyInputGroup"
                label="Доверенность"
                label-for="attorney"
              >
                <b-form-file
                  id="attorney"
                  v-model="form.file"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  name="attorney"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('file')"
                />
                <div
                  v-if="form.file.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.file.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.file.file)"
                  >{{ form.file.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="$v.form.$invalid || loading"
          :class="{'loading' : loading}"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import {validationMixin} from 'vuelidate';
import {validation} from '../../components/mixins/validation';
import {required} from 'vuelidate/lib/validators';
import moment from 'moment';
import notifications from '../../components/mixins/notifications';
import {
  contractorsAttorneyRead,
  contractorsAttorneyUpdate,
  contractorsDriverList,
  contractorsDriverRead,
  documentCreate,
  downloadFile,
} from '../../services/api';
import ElSelectClearable from '../../components/ElSelectClearable';

export default {
  name: 'AttorneysEdit',
  components: {ElSelectClearable, ElDatePickerInput},
  mixins: [validationMixin, notifications],
  props: ['attorneyId'],
  data() {
    return {
      form: {
        drivers: {
          options: [],
          value: null,
        },
        number: '',
        date: '',
        dateStart: '',
        dateEnd: '',
        file: '',
      },
      data: {},
      drivers: {
        columns: [
          'driver.fullname',
          'driver.status',
          'driver.passportSeries',
          'driver.passportNumber',
          'driver.passportIssueDate',
          'driver.passportIssuedBy',
          'driver.passportScreenFirst',
          'driver.passportScreenSecond',
          'contractFile',
          'actions',
        ],
        options: {
          headings: {
            'driver.fullname': 'ФИО',
            'driver.status': 'Статус',
            'driver.passportSeries': 'Серия',
            'driver.passportNumber': 'Номер',
            'driver.passportIssueDate': 'Дата выдачи паспорта',
            'driver.passportIssuedBy': 'Кем выдан',
            'driver.passportScreenFirst': 'Главная страница паспорта',
            'driver.passportScreenSecond': 'Страница с пропиской',
            'contractFile': 'Документы договорных отношений водителя и работодателя',
            'actions': '',
          },
          sortable: ['driver.fullname'],
          filterable: ['driver.fullname'],
          sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          perPage: 5,
          perPageValues: [5, 10, 25],
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Ни одного водителя не добавлено',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        theme: 'bootstrap4',
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: false,
    };
  },
  computed: {
    formStr() {
      const calculationFormData = {};
      if (this.drivers.items.length) {
        calculationFormData.drivers = [];
        this.drivers.items.map((driver) => {
          calculationFormData.drivers.push( {id: driver.driver.id} );
        });
      }
      if (this.form.number) {
        calculationFormData.number = this.form.number;
      }
      if (this.form.date) {
        calculationFormData.date = moment(this.form.date).format('YYYY-MM-DD');
      }
      if (this.form.dateStart) {
        calculationFormData.dateStart = moment(this.form.dateStart).format('YYYY-MM-DD');
      }
      if (this.form.dateEnd) {
        calculationFormData.dateEnd = moment(this.form.dateEnd).format('YYYY-MM-DD');
      }
      if (this.form.file) {
        calculationFormData.file = this.form.file;
      }
      return calculationFormData;
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations() {
    return {
      form: {
        drivers: '',
        number: {
          required,
        },
        date: {
          required,
        },
        dateStart: {
          required,
        },
        dateEnd: {
          required,
        },
        file: {
          required,
        },
      },
    };
  },
  mounted() {
    this.driverRemote('');
    this.contractorsAttorneyRead(this.$store.state.user.ownerId, this.attorneyId);
  },
  methods: {
    downloadFile,
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    async driverRemote(query) {
      this.loading = true;
      const response = await contractorsDriverList(this.$store.state.user.ownerId, {
        fio: query,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.form.drivers.options = response.data.items.map((item) => {
          item.name = item.driver.surname + ' ' + item.driver.name;
          if (item.driver.patronymic) {
            item.name = item.name + ' ' + item.driver.patronymic;
          }
          return {value: item.id, text: item.name};
        });
      }
      this.updateDriverOptions();
      this.loading = false;
    },
    async onDriverChoose(driverId) {
      this.loading = true;
      const response = await contractorsDriverRead(this.$store.state.user.ownerId, driverId);
      if (response && response.status === 200) {
        this.drivers.items = this.drivers.items.filter((x) => x.id !== response.data.id);
        response.data.driver.fullname = response.data.driver.surname + ' ' + response.data.driver.name;
        if (response.data.driver.patronymic) {
          response.data.driver.fullname = response.data.driver.fullname + ' ' + response.data.driver.patronymic;
        }
        this.drivers.items.unshift(response.data);
        this.form.drivers.value = null;
      }
      this.updateDriverOptions();
      this.loading = false;
    },
    deleteChosenDriver(driverId) {
      this.drivers.items = this.drivers.items.filter((item) => item.id !== driverId);
    },
    updateDriverOptions() {
      this.form.drivers.options = this.form.drivers.options.map((item) => {
        return {...item, ...{disabled: !!this.inContractorsList(item.value)}};
      });
    },
    inContractorsList(id) {
      return this.drivers.items.find((driver) => {
        return driver.id === id;
      });
    },
    clearDriversList() {
      this.drivers.items = [];
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await contractorsAttorneyUpdate(this.$store.state.user.ownerId, this.attorneyId, this.formStr);
        if (response && response.status === 200) {
          this.showSuccess('Доверенность обновлена');
          this.$router.push({path: '/attorneys'});
        }
        this.loading = false;
      }
    },
    async contractorsAttorneyRead(contractorId, attorneyId) {
      this.loading = true;
      const response = await contractorsAttorneyRead(contractorId, attorneyId);
      if (response && response.status === 200) {
        this.form.status = response.data.status;
        if (response.data.contractorDrivers) {
          this.drivers.items = response.data.contractorDrivers.map((contractorDriver) => {
            contractorDriver.driver.fullname = contractorDriver.driver.surname + ' ' + contractorDriver.driver.name;
            if (contractorDriver.driver.patronymic) {
              contractorDriver.driver.fullname = contractorDriver.driver.fullname + ' ' + contractorDriver.driver.patronymic;
            }
            return contractorDriver;
          });
        }
        this.form.number = response.data.number;
        this.form.date = response.data.date;
        this.form.dateStart = response.data.dateStart;
        this.form.dateEnd = response.data.dateEnd;
        if (response.data.file) {
          this.form.file = response.data.file;
        }
      }
      this.loading = false;
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else return !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    async documentCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.form[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadFile(key) {
      const file = this.form[key];
      if (file.size >= 15 * 1024 * 1024) {
        this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
      } else {
        this.getBase64(file).then((data) => {
          const upload = {
            base64file: data,
            originalName: file.name,
          };
          this.documentCreate(key, upload);
        });
      }
    },
    clearField() {
      this.$refs.editDriverAttorney._data.previousQuery = '';
      this.$refs.editDriverAttorney._data.query = '';
    },
  },
};
</script>

<style lang="scss">
</style>
